/*
    Api settings and custom useFetch hook for api calls
*/

import { useFetch } from 'treble-fetch';

interface IUseFetchPTR {
    (url: string, options?: {
        headers?: any,
        trigger?: any[],
        method?: 'POST' | 'GET' | 'PUT' | 'DELETE',
        baseUrlType?: any,
        body?: any,
        hold?: boolean,
        initialMount?: boolean,
        [key: string]: any
    }): {
        response: {
            data: any,
        },
        loading: boolean,
        error: any,
        refresh: (loading?: boolean) => void
    }
}

const useFetchPTR: IUseFetchPTR = (url: string, options?: any) => {
    const baseUrl = (window as any).gbl_React_App_Service_URL;
    const token = sessionStorage.getItem("token");    
    const baseHeader = { 'Content-Type': "application/json" }
    let methodType = (options?.method) ? options?.method : 'GET';
    let headers = (Array.isArray(options?.headers)) ? [baseHeader, ...options?.headers] :
        (options?.headers) ? [baseHeader, options?.headers] : baseHeader;

    return useFetch(
        `${baseUrl}${url}`,
        {
            method: methodType,
            headers: headers,
            ...options
        }
    ) as any
};

export default useFetchPTR;