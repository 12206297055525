import { createStore, useTreble } from 'treble-gsm';
import { TrebleFetchStore } from 'treble-fetch';
import { ReactRouterStore } from 'react-router-treble';
import { ProtransReactUIStore } from 'protrans-react-ui';
import { TAppStoreHook } from './interfaces';

const  useAppStore = () => useTreble() as TAppStoreHook;

const appStore = createStore(
    [       

    ], {
    extendStore: [
        TrebleFetchStore,
        ReactRouterStore,
        ProtransReactUIStore
    ]
}
);

export { useAppStore };
export default appStore;
