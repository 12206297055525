import React, { useState } from 'react';
import { Form, Col, Row, Button, Container } from 'react-bootstrap';
import { useTreble } from 'treble-gsm';
import { useAppStore } from "../../Store/Store";
import { useHistory } from "react-router-dom";
import md5 from 'md5';

import PTRUI from 'protrans-react-ui';
import { useFetchPTR } from '../../app-services';
import * as URL from '../_url-constants';

interface IUser {
    email: string;
    firstName: string;
    hub: string;
    hubId: number;
    id: number;
    isPasswordValidationRequired: boolean;
    lastName: string;
    passWord: string;
    userName: string;
    token: string;
}

export default function LoginPage() {
    const [{ }, Store] = useTreble();
    const [, appStore] = useAppStore();
    const { setUser, setToken, setIsAuth, isAuth } = PTRUI.useLogin();
    const [loginData, setLoginData] = useState<any>({ UserName: "", PassWord: "" });
    const [credentials, setCredentials] = useState<any>({ UserName: "", PassWord: "" }); 
    const message = PTRUI.useInAppMessage();
    const history = useHistory();

    const { response: loginUserResponse, refresh: loginRefresh, error: loginUserError } = useFetchPTR(
        `${URL.USER}/token`,
        {
            headers: { userName: loginData.UserName, password: md5(loginData.PassWord), 'Content-Type': "application/json" },           
            method: "GET" ,
            baseURLType: false
        }
    ); 

    //Set Login data
    React.useEffect(() => {

        if (loginUserResponse.data) {

            if (loginUserResponse && !loginUserError && loginUserResponse?.data && !loginUserResponse?.data?.Message) {
                let userData = (loginUserResponse.data as IUser);
                 
                if(userData?.id > 0)
                {
                    setIsAuth(true);                
                    setToken(userData?.token);
                    sessionStorage.setItem("token", userData?.token);
                    setUser({
                        id: userData?.id,
                        firstName: userData?.firstName ?? '',
                        lastName: userData?.lastName ?? '',
                        userName: userData?.userName ?? '',
                        email: "",
                        profilePic: ''
                    });
                    let UserSessionData = {
                        id: userData?.id,
                        userName: userData?.userName ?? '',
                        firstName: userData?.firstName ?? '',
                        lastName: userData?.lastName ?? '',
                        email: userData?.email ?? '',
                        hubId: userData?.hubId ?? 0,
                        hub: userData?.hub ?? ''
                    };
                    sessionStorage.setItem("UserSessionData", JSON.stringify(UserSessionData));
                    message.createLoading("Successfully login. Redirecting to landing page.");
                    setTimeout(() => {

                        if (history.location.pathname === "/") {
                            history.push("/checkcalls/")
                        }

                    }, 0);
                } 
                
            }

            else if (loginUserResponse?.data && loginUserResponse?.data?.Message && loginUserResponse?.data.Message != "")
                message.createError(loginUserResponse?.data?.Message);
            else
                message.createError("Error occurred while connecting to API service.");

        }
    }, [loginUserResponse]);

    const validationHandler = (): boolean => {
        let error = "";
        let isValid = true;

        if (loginData.UserName === "" && loginData.PassWord === "")
            error = "User name/ Password are required to login.";

        else if (loginData.UserName === "")
            error = "User name is required to login.";
       
            else if (loginData.PassWord === "")
            error = "Password is required to login.";

        if (error != "") {
            message.createError(error, { dismiss: true });
            isValid = false;
        }
        return isValid;
    }

    const loginClickHandler = () => {

        if (validationHandler()) { 
           setCredentials({ ...credentials, userName: loginData.UserName, password: (loginData.PassWord) }); 
           loginRefresh(); 
        }
    }

    
    
    return (

        <>
            <br />
            <Container fluid className="login-page">
                <Row className="justify-content-center align-items-center h-100vh">
                    {/** Login Page */}
                    <Col sm='12' >
                        <Row>
                            <Col sm='12' lg='12' >

                                <Form.Row>
                                    <Form.Group as={Col} className="mb-1">
                                        <Form.Control type="username" placeholder="Enter UserName" value={loginData.UserName}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setLoginData({ ...loginData, UserName: e.target.value })} />

                                    </Form.Group>
                                </Form.Row>
                                <Form.Row>
                                    <Form.Group as={Col} >
                                        <Form.Control type="password" placeholder="Enter Password" value={loginData.PassWord}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setLoginData({ ...loginData, PassWord: e.target.value })} />

                                    </Form.Group>
                                </Form.Row>
                                <Form.Row>
                                    <Form.Group as={Col} className="mb-1" >
                                        <Button className="btn btn-custom btn-block" onClick={() => loginClickHandler()}>LOGIN</Button>
                                    </Form.Group>
                                </Form.Row>
                                <Form.Row>
                                    <Form.Group as={Col}>
                                        <Form.Check type="checkbox" label="Remember me" />
                                    </Form.Group>
                                </Form.Row>

                                <Form.Row className="mt-5">
                                    <Form.Group className="mb-0" as={Col}>
                                        <p><i>Copyright &copy; 2021 ProTrans International Inc. All rights reserved.</i></p>
                                    </Form.Group>
                                </Form.Row>
                                <Form.Row className="float-right">
                                    <Form.Group className="mb-0" as={Col}>
                                        <p><i>Build 1.0.0.13536</i></p>
                                    </Form.Group>
                                </Form.Row>
                                <hr />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </>
    )
}