import React from 'react';
import Routes from './routes';
import { appStore } from './Store';
import AppNavigation from './app-navigation';
import AppStartUp from './app-startup';
import AppConfig from './app-config';
import PTRUI from 'protrans-react-ui';
import './App.scss';

function App() {
  return (
    <PTRUI.App
      config={AppConfig}
      startup={AppStartUp}
      navigation={AppNavigation}
      routes={Routes}
      store={appStore}
      basename={'/check-calls'}
    ></PTRUI.App>
  );
}

export default App;
