const CHECKCALLS = "/api/checkcall";
const LOAD = "/api/load";
const WORKEFFORT = "/api/workeffort";
const TRANSACTION = "/api/transaction";
const POSTALCODE = "api/postalcodes";
const PARTIES = "api/parties";
const CARRIER = "/api/carrier";
const USER = "/api/user";
const UserActions = "/api/useraction";

export {
    CHECKCALLS,
    LOAD,
    WORKEFFORT,
    TRANSACTION,
    POSTALCODE,
    PARTIES,
    CARRIER,
    USER,
    UserActions
};

