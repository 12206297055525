import {useEffect} from 'react';

/** Loads initial api data on app start up */

//services

//context
import {useTreble} from 'treble-gsm';

function AppStartup(){

    //get api data on app start up
  useEffect(() => {
    //service function
  },[])

  return(
    null
  )
}

export default AppStartup;