import React from "react";
import { NotFound } from 'protrans-react-ui';
import { lazyImport } from 'treble-fetch';
import { Route } from "react-router-treble";

//scenes
const CheckCallService = lazyImport(() => import('./scenes/check-call-service'));

function Routes() {

  return (
    
    <>

      {/**Check Call Service Page */}
        <Route exact path='/checkcalls/:loadTrackingNumber?'>
          <CheckCallService />
        </Route>
        <Route exact path='/'>
        <CheckCallService />
      </Route>
        {/** 404 Page Not Found Route */}
        <Route>
          <NotFound />
        </Route>

      </>
  );
}

export default Routes;
