import React from 'react';
import ReactDOM from 'react-dom';
import App from './components/App';

import './index.scss';

 //via: https://mariusschulz.com/blog/declaring-global-variables-in-typescript
 declare global {
  interface Window { gbl_React_App_Service_URL: any; }
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);
